function Ecuador(){
    return(
        <div>
            <div class="text-white container px-5">
                <h3>Genesis was born in Guayaquil, Ecuador on August 9th, 1999.</h3>
                <h3>While she was there, she loved eating:</h3>
                <br/> <br/> 

                <div class="container rounded bg-info">
                    <h1 class="display-2">ENCEBOLLADO</h1>
                </div>

                <div class="container">
                    <div class="d-flex flex-row justify-content-centers bg-dark">
                        <img src={require('./img/ecuador/fishsoup.jpg').default} height="350px"/>
                        
                        <div class="container text-white text-larger h5 ">
                            <p>
                                It's almost everything that I hate, put into one dish. Genesis
                                makes sure I always have an open mind about stuff. So I tried it.
                                Let me make this clear: I hate onions, and I'm not a huge fan of fish.
                                However, there is some brujeria going on here. . . It is delicious!
                            </p>
                            <p>
                                Genesis loves this food more than life itself. Her mom brought us real 
                                encebollado from Ecuador last time she went. It was delicious but I 
                                honestly believe that Genesis' encebollado was better. It tasted really
                                lime-y and delicious. I have never enjoyed biting into an onion or fish
                                so much in my life!!!
                            </p>
                        </div>
                        

                    </div>
                
                <br/><br/><br/>


                <div class="container rounded bg-info">
                    <h1 class="display-2">PAN DE YUCA</h1>
                </div>

                <div class="container">
                    <div class="d-flex flex-row justify-content-centers bg-dark">
                        <img src={require('./img/ecuador/pandeyuca.jpg').default} height="350px"/>
                        
                        <div class="container text-white text-larger h5 ">
                            <p>
                                These are really delicious. They are almost like cheese-bread.
                                I don't think there is any cheese in it. It's undercooked dough in
                                the middle. It sounds weird, but if you try it, you will love it.
                                It is a bit dry though, you can remedy that by dipping it in cafe.
                            </p>
                        </div>
                        

                    </div>
                
                <br/><br/><br/>
                </div>

                <div class="container rounded bg-info">
                    <h1 class="display-2">BOLON</h1>
                </div>

                <div class="container">
                    <div class="d-flex flex-row justify-content-centers bg-dark">
                        <img class=" border-0 rounded" src={require('./img/ecuador/bolon.jpeg').default} height="350px"/>
                        
                        <div class="container text-white text-larger h5 ">
                            <p>
                                Genesis loves these, she says that they eat this for breakfast in
                                Ecuador. 'Bolon' means 'Ball'. It's just a ball of plantain with
                                other yummy stuff inside. She really misses bolon, and always says
                                that she wishes she could have it.
                            </p>
                        </div>
                        

                    </div>
                
                <br/><br/><br/>
                </div>
                
                </div>
            </div>
        </div>
    );
}

export default Ecuador;